import Navigation from "./Navigation";
import ContentContainer from "./ContentContainer";
import {GiCartwheel} from "@react-icons/all-files/gi/GiCartwheel";
import {FaTrailer} from "@react-icons/all-files/fa/FaTrailer";
import {BsWrench} from "@react-icons/all-files/bs/BsWrench";
import ProductPreview from "./ProductPreview";
import TrailerCard from "./TrailerCard";
import trailers from '../trailers.json';
import Datenschutz from "./Datenschutz";
import Impressum from "./Impressum";
import Tires from "./Tires";
import Anfahrt from "./Anfahrt";

export default function MainContent() {
    return (
        <div>
            <Navigation/>
            <ContentContainer title='Horst Schönbeck' text='Reifenservice und Anhängerverleih' img='banner_00'/>
            <div className="container">
                <div className="products">
                    <ProductPreview name="Reifen" icon={GiCartwheel()}
                                    description="Bei mir können Reifen jeglicher Marke und Größe erworben werben. Außerdem können diese auch bei uns montiert, gewuchtet und eingelagert werden."/>
                    <ProductPreview cssClassName="products--item m-lg-5 mt-5 mb-5 mt-md-0 mb-md-0" name="Kfz-Teile"
                                    icon={BsWrench()}
                                    description="Ich kann Ihnen Kfz-Ersatzteile aller Marken besorgen, sowie einen günstigen und fachgerechten Einbau vermitteln."/>
                    <ProductPreview name="Anhänger" icon={FaTrailer()}
                                    description="Ich habe mehrere Anhänger zur Auswahl, die ich zum Anmieten bereitstelle. Die Daten dazu können Sie den Kärtchen weiter unten entnehmen."/>
                </div>
            </div>
            <div className="trailers--container pt-5"
                 id="trailer">
                <h2 className="mb-5 section-heading">Anhänger</h2>
                <div className="trailers mb-5">
                    {trailers.map((trailer, index) => {
                        return <TrailerCard
                            key={index}
                            trailer={trailer}/>
                    })}
                </div>
            </div>
            <div className="container"
                 id="wheels">
                <Tires/>
            </div>
            {/*<div className="container"*/}
            {/*     id="kfz">*/}
            {/*    <h2 className="mb-5 mt-5 section-heading">Kfz</h2>*/}
            {/*</div>*/}
            <div className="container-fluid"
                 id="anfahrt">
                <Anfahrt/>
            </div>
            <div className="container"
                 id="impressum">
                <Impressum/>
            </div>
            <div className="container justify-text"
                 id="datenschutz">
                <Datenschutz/>
            </div>
        </div>
    );
}