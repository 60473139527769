export default function ProductPreview(props) {
    const cssClass = props.cssClassName ? props.cssClassName : 'products--item';

    return (
        <div className={cssClass}>
                        <span className='product--icon'>
                            {props.icon}
                        </span>
            <h3 className='product--name heading--bold'>{props.name}</h3>
            <div className="product--description">
                <p>
                    {props.description}
                </p>
            </div>
        </div>
    );
}