export default function Tires() {
    return (
        <div>
            <h2 className="mb-5 mt-5 section-heading">Reifen</h2>
            <div className="row clipped-containers">
                <div className="col-md-4 p-1 p-md-0 mt-3 mb-5">
                    <div className="col-md-12 tire-background container-background clipped-container">
                    </div>
                    <div className="col-md-9 m-auto mt-3">
                        <p className="justify-content-center justify-text">
                            Bei uns können Sie Alufelgen jeglicher Größe und Hersteller günstig erwerben...
                        </p>
                    </div>
                </div>
                <div className="col-md-4 p-1 p-md-0 mt-3 mb-5">
                    <div className="col-md-12 montieren-background container-background clipped-container">
                    </div>
                    <div className="col-md-9 m-auto mt-3 justify-text">
                        <p>
                            ... um die Reifen im Anschluss daran auf die Felgen zu ziehen.
                            Das ist uns von 12' bis 24' möglich.
                        </p>
                    </div>
                </div>
                <div className="col-md-4 p-1 p-md-0 mt-3 mb-5">
                    <div className="col-md-12 wuchten-background container-background clipped-container">
                    </div>
                    <div className="col-md-9 m-auto mt-3 justify-text">
                        <p>
                            Natürlich wuchten wir Ihre Reifen auch vor der Montage.
                            Hier haben wir ebenfalls die Möglichkeit Reifen zwischen 12' und 24' zu wuchten.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
}