import SlantedContainer from "./SlantedContainer";
import {AiOutlinePhone} from "@react-icons/all-files/ai/AiOutlinePhone";
import {AiOutlineMail} from "@react-icons/all-files/ai/AiOutlineMail";

export default function ContentContainer(props) {
    const image = 'img/' + props.img + '.png';

    return (
        <SlantedContainer slanted={false}>
            <div className="row align-items-center">
                <div className="modal fade" id="contactModalCenter" tabIndex="-1" role="dialog"
                     aria-labelledby="contactModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content background-primary">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Kontakt</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body d-flex flex-column justify-content-center justify-text align-content-between">
                                <span><b>Horst Schönbeck</b></span>
                                <span className="ms-3"><AiOutlinePhone/> <a href="tel:+499936903963">09936 / 90 3963</a></span>
                                <span className="ms-3"><AiOutlinePhone/> <a href="tel:+15168514605">0151 / 685 14605</a></span>
                                <span className="ms-3"><AiOutlineMail/> <a href="mailto:schoenbeck.horst@t-online.de">schoenbeck.horst@t-online.de</a></span>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="cta--button" data-dismiss="modal">Schließen</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="content-container_column">
                            <div className="content-container_content">
                                <h3 className="content-container_title">
                                    {props.title}
                                </h3>
                                <p className="content-container_text text-sm-left">{props.text}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <button className="cta--button" data-toggle="modal" data-target="#contactModalCenter">KONTAKT</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-3 mt-md-0">
                    <div className="content-container_column text-sm-left">
                        <div className="content-container_content">
                            {props.img && <img src={image} alt='Image' className="content-container_title"/>}
                        </div>
                    </div>
                </div>
            </div>
        </SlantedContainer>
    );
}