export default function SlantedContainer(props) {
    let cssClass = props.slantedRight ? "container-fluid content-container container-slanted container-slanted__right" : "container-fluid content-container container-slanted container-slanted__left";
    if (!props.slanted) {
        cssClass = "container-fluid content-container container-slanted"
    }

    return (
        <div className={cssClass}>
            {props.children}
        </div>
    );
}