export default function TrailerCard(props) {
    return (
        <div className="trailer">
            <div className="trailer--image">
                <img src={props.trailer.image} alt="trailer"/>
            </div>
            <div className="trailer--text">
                <p className="trailer-type">
                    {props.trailer.type}
                </p>
                <h2 className="trailer-title">{props.trailer.title}</h2>
                <div className="trailer-details">
                    <p className="trailer-nutzlast">Nutzlast <span
                        className="pull-right">{props.trailer.nutzlast}</span></p>
                    <p className="trailer-gesamt">zul. Gesamtgewicht <span
                        className="pull-right">{props.trailer.gesamtGewicht}</span></p>
                    <p className="trailer-ladefl">Länge <span className="pull-right">{props.trailer.laenge}</span>
                    </p>
                    <p className="trailer-ladefl">Breite <span className="pull-right">{props.trailer.breite}</span>
                    </p>
                    <p className="trailer-ladefl">Höhe <span className="pull-right">{props.trailer.hoehe}</span></p>
                </div>
            </div>
            <div className="trailer-price">
                {props.trailer.price}€ <span className="lead">&nbsp;/ 24h</span>
            </div>
        </div>
    );
}