export default function Anfahrt() {
    return (
        <div>
            <h2 className="mb-5 mt-5 section-heading">Anfahrt</h2>
            <iframe
                className="google--map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4831.308226033534!2d12.885923989633566!3d48.68082858879404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477509a7215d6aad%3A0xfe968c352361b831!2sRamsdorf%2033%2C%2094574%20Wallerfing!5e0!3m2!1sde!2sde!4v1633940164837!5m2!1sde!2sde"
                loading="lazy">
            </iframe>
        </div>
    );
}